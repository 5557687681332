import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { MetaData } from "../../components/meta"
import Layout from "../../components/layout/defaultlayout"
import { ClickMe, Photography } from "../../components/common/pages"
import { pagesConfig } from "../../utils/configs/site"
import "../../assets/css/main.css"
import IMG from "../../assets/images/summertrip/summertrip - 5.jpeg"
import { HighlightCard } from "../../components/common/cards"
import { IndexHighlights } from "../../components/common/sections"

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */
const PageComponent = ({ page }) => {
   
    const DefaultComponent = () => {
        return (
            <div className="container" id={page.slug}>
                <h1 className="content-title">{page.title}</h1>
                <article className="content">
                    <section
                        className="content-body load-external-scripts"
                        dangerouslySetInnerHTML={{ __html: page.html }}
                    />
                </article>
            </div>
        )
    }

    const IndexComponent = () => {
        return (
            <div className="container" id={page.slug}>
                <IndexHighlights/>
                <article className="content">
                    <section
                        className="content-body load-external-scripts"
                        dangerouslySetInnerHTML={{ __html: page.html }}
                    />
                </article>
            </div>
        )
    }
    const ClickMeComponent = () => {
        let clickMeCards = eval(page.codeinjection_head)
        return (
            <div className="container" id={page.slug}>
                <ClickMe navigation={clickMeCards} />
                <article className="content">
                    <section
                        className="content-body load-external-scripts"
                        dangerouslySetInnerHTML={{ __html: page.html }}
                    />
                </article>
            </div>
        )
    }
    const PhotographyComponent = () => {
        return (
            <div className="container" id={page.slug}>
                <article className="content">
                    <section
                        className="content-body load-external-scripts"
                        dangerouslySetInnerHTML={{ __html: page.html }}
                    />
                </article>
                <Photography />
            </div>
        )
    }
    const NoTitleComponent = () => {
        return (
            <div className="container" id={page.slug}>
                <article className="content">
                    <section
                        className="content-body load-external-scripts"
                        dangerouslySetInnerHTML={{ __html: page.html }}
                    />
                </article>
            </div>
        )
    }
    
    if (page.slug === "index") return <IndexComponent />
    else if (page.slug === "click-me") return <ClickMeComponent />
    else if (page.slug === "photography") return <PhotographyComponent />
    else if (page.slug === "about") return <NoTitleComponent />
    else return <DefaultComponent />
}

const Page = ({ data, location }) => {
    const page = data.ghostPage
    const isHome = page.slug === "index" ? true : false
    return (
        <>
            <MetaData data={data} location={location} type="website" />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout location={location} isHome={isHome}>
                <PageComponent page={page} />
            </Layout>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page
